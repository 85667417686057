import './styles.scss'

import Layout from '../../layout'

const Home = () => {
  console.log('done')
  return (
    <Layout documentTitle="Welcome" className="home">
      &lt; Tony &bull; 7 &bull; Bones /&gt;
    </Layout>
  )
}
export default Home
